.menu-btn {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  place-items: end;
  z-index: 999;
}
.menu-btn .menu-btn-content {
  color: #a22121;
  font-size: 26px;
  font-weight: 600;
  position: relative !important;
  display: block;
  top: 0;
  transition: top 0.5s;
}
.menu-btn .menu-btn-content:before {
  content: attr(data-hover);
  position: absolute;
  top: 33px;
  left: 0;
  width: 100%;
  height: 33px;
}
.menu-btn .menu-btn-content:hover {
  top: -36px;
  position: relative;
}
.menu-content {
  overflow: hidden !important;
  position: absolute;
  left: 50%;
  top: 0;
  z-index: 99;
  translate: -50%;
  background: #1a1818;
  flex-direction: column !important;
  align-items: center;
  display: flex;
}
.menu-height {
  height: 100vh;
  overflow: hidden !important;
}
.menu-content-body {
  position: relative;
  height: 85vh;
}
.menu-model model-viewer {
  width: 100%;
  height: 70vh;
  opacity: 0.4;
}
.menu-content-body-list ul {
  padding: 0;
}
.menu-content-body-list ul li {
  list-style: none;
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 1.5rem;
  color: #fff;
  cursor: pointer;
  transition: 0.5s;
}
.menu-content-body-list ul li a:hover {
  list-style: none;
  cursor: pointer;
  transition: 0.5s;
  background-clip: text;
  background-image: linear-gradient(
    to right,
    #ab2321,
    #ab2321,
    #ab2321,
    #ab2321
  );
  color: #f4f4f4;
  font-weight: 700;
  -webkit-text-stroke-color: transparent;
  -webkit-text-stroke-width: 2px;
}
.menu-content-body-list ul li a {
  color: #fff;
  border-bottom: none !important;
  text-decoration: none !important;
}
@media (max-width: 767px) {
  .menu-height {
    top: 13vh;
    position: relative;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .menu-height {
    top: 10vh;
    position: relative;
  }
  .menu-content-body-list ul li {
    list-style: none;
    font-size: 23px;
    line-height: 32px;
    font-weight: 700;
    color: #a22121;
    cursor: pointer;
    transition: 0.5s;
  }
}
@media (max-width: 542px) {
  .menu-content-body-list ul li {
    list-style: none;
    font-size: 17px;
    line-height: 25px;
    font-weight: 700;
    color: #a22121;
    cursor: pointer;
    transition: 0.5s;
  }
  .menu-btn .menu-btn-content {
    font-size: 20px;
  }
}
.out-cube {
  animation: 0.5s ease-in-out forwards out-cube;
}
.out-list {
  animation: 0.5s ease-in-out 0.8s forwards out-list;
}
@keyframes out-list {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@keyframes out-cube {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  50% {
    transform: translateY(-100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}
@media (min-width: 1700px) {
  .menu-content-body-list ul li {
    list-style: none;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 700;
    color: #a22121;
    cursor: pointer;
    transition: 0.5s;
  }
}
