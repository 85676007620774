@font-face {
  font-family: 'CustomFont'; /* Font'un adını istediğiniz şekilde belirtin */
  src: url('./assets/fonts/Montserrat/static/Montserrat-Regular.ttf') format('ttf'); /* Font dosyanızın yolunu ve formatını belirtin */

}
@font-face {
  font-family: 'CustomFont2'; /* İkinci fontun adını belirtin */
  src: url('./assets/fonts/Montserrat/static/Montserrat-Regular.ttf') format('ttf'); /* İkinci fontun yolunu ve formatını belirtin */
}

body {
  font-family: 'CustomFont', sans-serif !important; /* Belirttiğiniz fontu kullanın, yedek olarak sans-serif kullanılabilir */
}

.language-top{
  top: 2vh;
}

html, body{
  background-color: #F4F4F4  !important;
}
:root{
  background: #F4F4F4 ;
}
.main-become{
  color: #fff;
                                      width: 5px;
                                      height: 24px;
                                     border-radius: 50%,
}
.products-page-inner{
  background-color: #F4F4F4 ;
}
.bg-main{
  background-color: #F4F4F4 
}
.button-focus{
  
    color: white !important;
    background-color: #a22121;
    width: max-content;
    text-align: center;
    font-weight: 700;
    font-size: 1.2rem !important;
    padding: 0.5vh 1vh;
    border-radius: 12px;

}
img{
  max-width: 100%;
}
a{
 text-decoration: none !important;
}
.bg-gray{
  background-color: #707070;
  border-radius: 12px;
  padding: 4vh;
  color: #1a1818 !important;
 
}
.no-image{
  background-color: #707070;
  height: 100%;
  border-radius: 12px ;
}

.light-bg-gray{
  background-color: #5e5e5e;
  border-radius: 12px ;
  /* height: 100%;  */
}
.osi-icon{
  height: 3vh;
  padding: 0vh 1vh;
}

.loader img{
  max-width: 100%;
}

.title-main{
  background-clip: text;
background-image: linear-gradient(to right,#AB2321,#AB2321,#AB2321,#AB2321);
color: #F4F4F4;
font-size: 7rem;
font-weight: 800;
letter-spacing: 8px;
/* padding: 2vh; */
-webkit-text-stroke-color: transparent;
-webkit-text-stroke-width: 2px;
text-align: center;

}

.title-main-blog , .title-main-news{
  background-clip: text;
background-image: linear-gradient(to right,#AB2321,#AB2321,#AB2321,#AB2321);
color: #F4F4F4;
font-size: 4rem;
font-weight: 800;
letter-spacing: 8px;
/* padding: 2vh; */
-webkit-text-stroke-color: transparent;
-webkit-text-stroke-width: 2px;
text-align: center;

}
.form-control{
background-color: #707070 !important;
color: white !important;
border: 1px solid white !important;
}
.form-group{
  color: white;
}

 /* İlgili input alanına stil eklemek için örnek bir seçici */
input[type="text"]::placeholder {
  color:white;
}
textarea[type="text"]::placeholder {
  color:white;
}
::marker {
  list-style-type: none !important;
}
.title-sticky{
  position: sticky;
  position: -webkit-sticky;
  top: 8vh;
  z-index: -1;
}

ul {
   /* list-style-type: none !important;  */
  padding: 0 !important;
}
li {
  list-style-type: disclosure-closed;

}
/* li{
  list-style-type: none !important;
} */
li:focus-visible {
  outline: #a22121 !important;
 }
.focus_text{
  position: relative;
  z-index: 99999;
}
.fixed-container {
  position: fixed;
}
.none-main{
  display: none !important;
}
.model-two model-viewer{
  width: 100%;
  height: 60vh;
}
.menu-home{
  display: none;
  position: fixed;
  margin-top: 15vh;
  z-index: 1;
}
@keyframes downing-page {
  from {
      transform: translateY(-100vh);
  }
  to{
      transform: translateY(0);
  }
}

.downing-page-div{
  position: absolute;
  top: 0;
  z-index: 1 !important;
  left: 0;
  width: 100%;
  height: 100%;  background-color:#f4f4f4;
  opacity: 1 !important;
  z-index: 1 !important;
}



/* .downing-img{
  background-size: contain;
  animation: down-img 2s 1 ;
  animation-delay: 4000ms;
}  */


.clickImgContainer {
  position: relative;
  text-align: center;
}

.clickImg {
  /* min-width: max-content
  ; */
  width: 100%;
  height: 100vh;
  opacity: 0.5;
}

.button-focus-click {
  position: absolute;
  cursor: pointer;
  top: 50%;
  left: 50%;
  color: white !important;
  background-color: #a22121;
  width: max-content;
  text-align: center;
  padding: 0vh 1vh;
  border-radius: 12px;
  transform: translate(-50%, -50%);
 
}

.content {

  top: 50%;
  left: 50%;
  overflow: hidden;
  font-size: 20px;
  line-height: 40px;
  color: #ecf0f1;
}
.content__container {
  font-weight: 600;
  overflow: hidden;
  height: 40px;
  padding: 0 10px;
}


.content__container__text {
  display: inline;
  float: left;
  margin: 0;
}
.content__container__list {


  list-style: none;
  -webkit-animation-name: change;
  -webkit-animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  animation-name: change;
  animation-duration: 10s;
  animation-iteration-count: infinite;
}
.new-img{
  width:50% !important; 
}

@-webkit-keyframes opacity {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@-webkit-keyframes change {
  0%, 12.66%, 100% {
    transform: translate3d(0, 0, 0);
  }
  16.66%, 29.32% {
    transform: translate3d(0, -25%, 0);
  }
  33.32%, 45.98% {
    transform: translate3d(0, -50%, 0);
  }
  49.98%, 62.64% {
    transform: translate3d(0, -75%, 0);
  }
  66.64%, 79.3% {
    transform: translate3d(0, -50%, 0);
  }
  83.3%, 95.96% {
    transform: translate3d(0, -25%, 0);
  }
}
@-o-keyframes opacity {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@-o-keyframes change {
  0%, 12.66%, 100% {
    transform: translate3d(0, 0, 0);
  }
  16.66%, 29.32% {
    transform: translate3d(0, -25%, 0);
  }
  33.32%, 45.98% {
    transform: translate3d(0, -50%, 0);
  }
  49.98%, 62.64% {
    transform: translate3d(0, -75%, 0);
  }
  66.64%, 79.3% {
    transform: translate3d(0, -50%, 0);
  }
  83.3%, 95.96% {
    transform: translate3d(0, -25%, 0);
  }
}
@-moz-keyframes opacity {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@-moz-keyframes change {
  0%, 12.66%, 100% {
    transform: translate3d(0, 0, 0);
  }
  16.66%, 29.32% {
    transform: translate3d(0, -25%, 0);
  }
  33.32%, 45.98% {
    transform: translate3d(0, -50%, 0);
  }
  49.98%, 62.64% {
    transform: translate3d(0, -75%, 0);
  }
  66.64%, 79.3% {
    transform: translate3d(0, -50%, 0);
  }
  83.3%, 95.96% {
    transform: translate3d(0, -25%, 0);
  }
}
@keyframes opacity {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
@keyframes change {
  0%, 12.66%, 100% {
    transform: translate3d(0, 0, 0);
  }
  16.66%, 29.32% {
    transform: translate3d(0, -25%, 0);
  }
  33.32%, 45.98% {
    transform: translate3d(0, -50%, 0);
  }
  49.98%, 62.64% {
    transform: translate3d(0, -75%, 0);
  }
  66.64%, 79.3% {
    transform: translate3d(0, -50%, 0);
  }
  83.3%, 95.96% {
    transform: translate3d(0, -25%, 0);
  }
}


@media only screen and (max-width: 600px) {
  .img-slider-main{
    height: 3% !important;
    width: 55% !important;
  }
  .slider-text-main {
    background-clip: text;
    background-image: linear-gradient(
      to right,
      #f4f4f4,
      #f4f4f4,
      #f4f4f4,
      #f4f4f4
    );
    color: black;
    /* font-size: 2rem !important; */
    font-weight: 800;
    /* letter-spacing: 8px;
    padding: 2vh; */
    -webkit-text-stroke-color: transparent;
    -webkit-text-stroke-width: 2px;
  
  }
  
  .title-main{
    font-size: 2rem;
   } 
   .title-main-blog , .title-main-news{
    font-size: 1.5rem;
   } 
 .bg-gray{
 padding: 2vh;
 }
}@media only screen and  (max-width: 768px)  {
  .title-main{
    font-size: 2rem;
  }
  .title-main-blog , .title-main-news{
    font-size: 1.5rem;
  }
  .language-top{
    top: 0;
  }
  .language-main select{
    font-size: 0.8rem;
  }

}
@media (min-width: 767px) and (max-width: 1025px) {
  .title-main{
    font-size: 4rem;
  }
  .title-main-blog , .title-main-news{
    font-size: 3rem;
  }
 
}
@media (min-width: 767px) and (max-width: 1455px) {
  .title-main{
    font-size: 4rem;
  }
  .title-main-blog, .title-main-news{
    font-size: 3rem;
  }
  .language-top{
    top: 1vh;
   
  }
}


::marker{
  color: #a22121 !important;
}

@media only screen and (min-width: 1900px) {
  .white-background {
    width: 120vw !important;
 }

 
}
 .downing-page-div{
    overflow-x: hidden !important;

  }


::-webkit-scrollbar {
  width: 2px; 
}

::-webkit-scrollbar-thumb {
  background-color: transparent; 
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

* {
  scrollbar-color: white #f1f1f1; 
}

*::-webkit-scrollbar {
  width: 2px; 
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  height: 80vh !important;
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-button-next, .swiper-rtl .swiper-button-prev{
  color: rgba(255, 255, 255, 0.645) !important;
}
.swiper-button-prev, .swiper-rtl .swiper-button-next{
  color: rgba(255, 255, 255, 0.645) !important;
}
.swiper-pagination-bullet-active{
  background: white !important;
}


.swiper-text-1{

    position: absolute;
    top: 10%;
    left: 50%;
    transform: translate(-50%, -10%);
    font-size: 24px;

}
.swiper-text-2{

  position: absolute;
  top: 10%;
right: 10%;
  transform: translate(10%, -10%);
  font-size: 24px;

}
.b_radius {
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.homepage-main .mySwiper .img-slider-main{

 width: auto;
 height: 8vh;
}
.slider-text-main {
  background-clip: text;
  background-image: linear-gradient(
    to right,
    #f4f4f4,
    #f4f4f4,
    #f4f4f4,
    #f4f4f4
  );
  color: black;
  /* font-size: 4rem; */
  font-weight: 800;
  /* letter-spacing: 8px;
  padding: 2vh; */
  -webkit-text-stroke-color: transparent;
  -webkit-text-stroke-width: 2px;

}

.main-loader svg{
  height: 50vh;
}